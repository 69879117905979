<template>
  <v-container id="about" tag="section" class="container">
    <section id="about-me">
      <div class="py-12" />

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">
          Hi, nice to meet you! I'm Mahsa.
        </h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider class="mb-1" />

          <v-divider />
        </v-responsive>

        <v-responsive class="mx-auto title font-weight-light mb-8" max-width="720">
          <v-carousel hide-delimiters v-model="model" class="carousel" :cycle="true" :show-arrows="false" height="100"
            hide-delimiter-background delimiter-icon="mdi-minus">
            <v-carousel-item v-for="(description) in descriptors" :key="description">
              <v-sheet color="rgb(255, 255, 255)" height="100%">
                <v-row class="fill-height" align="center" justify="center">
                  <div class="description">
                    <i>{{ description }}</i>
                  </div>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
          <p>As your dedicated realtor, I am here to guide you through every step of your real estate journey, ensuring
            you’re well informed and confident.</p>

          <p>Whether you're a first-time homebuyer or expanding your portfolio, my commitment to education, guidance, and
            personalized service ensures a seamless and enjoyable experience. I pride myself on building lasting
            relationships with my clients and offering unparalleled attention to detail. Outside of real estate, I
            love to try new restaurants, go on walks, practice yoga, and travel the world. Let's turn your real estate
            goals into reality!</p>
        </v-responsive>

        <v-avatar class="elevation-12 mb-12" size="250">
          <v-img :src="require(`@/assets/images/about_me.JPG`)" />
        </v-avatar>
        <p class="mx-auto title font-weight-light mb-8">
          <b><i>Delivering an integrity-based and transparent approach to real estate</i></b>
        </p>
        <div />
      </v-container>

      <div class="py-12" />
    </section>
  </v-container>
</template>

<script>
export default {
  name: 'HomeAbout',
  data: () => ({
    model: 0,
    descriptors: [
      'Your realtor. Your advisor. Your home match-maker.',
      'Your expert marketer. Your friend. Your home girl.',
    ],
  }),
}
</script>
<style scoped>
.description {
  color: black;
  font-weight: bold;
}

.container {
  background-color: #ffffff;
  max-width: 100%;
  margin-top: 10px;
}
</style>
